import { BrowserRouter , Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import Header from './components/Header/Header';
import Footer from "./components/Footer";

function RoutesApp(){
    return(
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" element={ <Home/> } />
            </Routes>
            <Footer/>
        </BrowserRouter>
    )
}

export default RoutesApp;