import React from 'react';
import  instagramlogo  from '../../img/home/instagramlogo.jpg';
import  youtubelogo  from '../../img/home/youtubelogo.jpg';
import  linkedinlogo  from '../../img/home/linkedinlogo.jpg';
import './styles.css';

function ContactSection() {
  return (
    <section className="contact-section">
      <h2 className="contact-title">CONTATOS</h2> {/* Apply the contact-title class */}
      <div className="social-buttons">
        <a href="https://www.instagram.com/saito_kun/" target="_blank" rel="noopener noreferrer">
        <div className="cta">
          <img src={instagramlogo} alt="Instagram" />
        </div>
        </a>
        <a href="https://www.youtube.com/channel/UCMF1P_U2Q8TqIHRS9AqZOaQ" target="_blank" rel="noopener noreferrer">
        <div className="cta">
          <img src={youtubelogo} alt="YouTube" />
        </div>
        </a>
        <a href="https://www.linkedin.com/in/anselmo-saito-b97300ba/" target="_blank" rel="noopener noreferrer">
        <div className="cta">
          <img src={linkedinlogo} alt="LinkedIn" />
        </div>
        </a>
      </div>
    </section>
  );
}

export default ContactSection;
