import React from 'react';
import YouTube from 'react-youtube';
import './YouTubeVideo.css'; // Import the CSS file

function YouTubeVideo({ videoId }) {
  const opts = {
    // Your options here
  };

  return (
    <div className="youtube-video"> {/* Apply the class */}
      <YouTube videoId={videoId} opts={opts} />
    </div>
  );
}

export default YouTubeVideo;
