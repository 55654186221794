import React, { useState, useEffect } from 'react';
import './styles.css';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
      // Reset the timeout when the user scrolls
      clearTimeout(hideButtonTimeout);
      startHideButtonTimer();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  let hideButtonTimeout;

  const startHideButtonTimer = () => {
    hideButtonTimeout = setTimeout(() => {
      setIsVisible(false);
    }, 1000); // 1000ms (1 second) delay before hiding
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // Clear the timeout when the component unmounts
      clearTimeout(hideButtonTimeout);
    };
  }, []);

  return (
    <button
      className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    >
      &#9650; {/* Unicode for an upward-pointing arrow */}
    </button>
  );
}

export default ScrollToTopButton;
