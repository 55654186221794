// Import necessary modules and components
import React, { useState } from 'react';
import './LandingPage.css'; // Import your CSS styles
import VideoSection from '../../components/VideoSection/VideoSection';
import ContactSection from '../../components/ContactSection/ContactSection';
import YouTubeVideo from '../../components/VideoSection/YoutubeVideoSection';
import ScrollToTopButton from '../../components/ScrollToTopButton/ScrollToTopButton';
import curious from '../Home/curious.jpg';
import student from '../Home/student.jpg';
import graduated from '../Home/graduated.jpg';
import nerd from '../Home/nerd.jpg';
import salary from '../Home/salary.jpg';
import joboffer from '../Home/joboffer.jpg';
import gringa from '../Home/gringa.jpg';
import mentor from '../Home/mentor.PNG';

// Create the LandingPage component
function Home() {
  const estiloDestaque = {
    color: 'red', // Substitua pelo código de cor forte desejado
  };
  return (
    <div className="landing-page">
      <main>
        <section className="hero">
          <div className="hero-content">
            <ScrollToTopButton/>
          </div>
        </section>

        <section className="video-section">
            <VideoSection/>
            
        </section>
        <br></br>
        <br></br>
        <section id="public-section" className="features">
          <h2><span style={estiloDestaque}>PARA QUEM É</span> O ENGENHEIRO TECH DE ELITE?</h2>
          <div className="feature-list">
            <div className="feature-item">
              <img className="public" src={curious} alt="Feature 1" />
              <h3>Interessados sobre TI</h3>
              <p>Todo aquele que ja sentiu vontade de viver nessa area.</p>
            </div>
            <div className="feature-item">
              <img className="public" src={student} alt="Feature 2" />
              <h3>Estudantes (faculdade ou não)</h3>
              <p>Quem esta criando um plano de estudo ou esta na faculdade.</p>
            </div>
            <div className="feature-item">
              <img className="public" src={graduated} alt="Feature 3" />
              <h3>Recem Formados</h3>
              <p>Aqueles que se formaram mas sentem dificuldade com entrevistas.</p>
            </div>
            <div className="feature-item">
              <img className="public" src={nerd} alt="Feature 4" />
              <h3>Qualquer um com dificuldade em entrevista</h3>
              <p>Sentiu se humilhado por ser recusado mesmo sendo esforçado nota A </p>
            </div>
          </div>
        </section>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <section id="features-section" className="features">
          <h2><span style={estiloDestaque}>CONQUISTAS</span></h2>
          <div className="feature-list">
            <div className="feature-item">
              <img className="conquistas" src={salary} alt="Feature 1" />
              <h3>Salários Elevados</h3>
              <p>Pertença ao grupo dos 5% do Brasil que faz mais de 5 digitos de renda</p>
            </div>
            <div className="feature-item">
              <img className="conquistas" src={gringa} alt="Feature 2" />
              <h3>Ofertas na Gringa</h3>
              <p>Descubra como atrair atenção dos recrutadores na gringa e trabalhar mundo a fora.</p>
            </div>
            <div className="feature-item">
              <img className="conquistas" src={mentor} alt="Feature 3" />
              <h3>Adeus Sindrome de Impostor</h3>
              <p>Obtenha o conhecimento que te tornará confiante para as posições que obter.</p>
            </div>
            <div className="feature-item">
              <img className="conquistas" src={joboffer} alt="Feature 4" />
              <h3>Desemprego nunca mais</h3>
              <p>Afaste o medo do desemprego com a enorme quantia de empregos que terá.</p>
            </div>
          </div>
        </section>

        <section id="contact-section">
            <ContactSection/>
        </section>
      </main>
    </div>
  );
}

export default Home;
