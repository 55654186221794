import React, { useState } from 'react';
import './HeaderTest.css';
import { Link } from 'react-scroll';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
        <div className="logo">
          <img src="iconlogo.png" alt="Anselmo Saito Services Logo" />
        </div>
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
      </div>
      <nav className={`nav ${isMobileMenuOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <Link
                  to="video-section"
                  spy={true}
                  smooth={true}
                  offset={-70} // Adjust the offset as needed for your header
                  duration={500}
                >
              <a href="#">Apresentacao</a>
              </Link>
            </li>
          <li>          <Link
                to="public-section"
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed for your header
                duration={500}
              ><a href="#">Publico</a>
              </Link>
              </li>
          <li>
          <Link
                to="features-section"
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed for your header
                duration={500}
              >
            <a href="#">Conquistas</a>
            </Link>
            </li>
          <li>
          <Link
                to="contact-section"
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed for your header
                duration={500}
              >
                <a href="#">Contato</a>
                </Link>
                </li>
          {/* Add more navigation options as needed */}
        </ul>
      </nav>
    </header>
  );
}

export default Header;