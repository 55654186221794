import './footer.css';
import { Link } from 'react-router-dom';

function Footer(){
    return(
    <footer>
        <p>&copy; 2023 Anselmo Saito Services</p>
    </footer>
    )
}

export default Footer;