import React from 'react';
import './styles.css';
import YouTubeVideo from './YoutubeVideoSection';

function VideoSection() {

  const handleButtonClick = () => {
      window.location.href = 'https://pay.kiwify.com.br/5MoNnpk';
  };
  const estiloDestaque = {
    color: 'red', // Substitua pelo código de cor forte desejado
  };
  return (
    <section className="video-section">
      <h2>ESTE VIDEO CONTEM O <span style={estiloDestaque}>SEGREDO</span> PARA BRILHAR NA ENTREVISTA DE TI</h2> {/* Add the title */}
      <div className="video-container">
        <YouTubeVideo videoId={"0PEvUywW4xg"}/>
        <div className={"fixed-button-container"}>
                <button className="cta-button" onClick={handleButtonClick}>
                ADQUIRA JA
                </button>
        </div>
      </div>
    </section>
  );
}

export default VideoSection;